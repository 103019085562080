<template>
	<div class="content" :style="cssVars">
		<vue-headful
			:title="pageTitle"
		/>
		<!-- <Load v-if="isLoading"></Load> -->
		 <loading :active.sync="isLoading"
		         :is-full-page="true"/>
		
		<div v-if="!isLoading && artworkError" class="error-code">
			{{ $t('no_resources') }}
		</div>
		
		<div v-else-if="!isLoading && isText" class="content-text">
			<h1>{{ resource.title }}</h1>
			<p v-html="resource.locale.content"/>
		</div>
		
		<div v-else-if="!isLoading && isImage" class="content-image">
			<div class="container">
				<div v-if="customization && customization.artworkCustomizationEnabled && customization.artworkBackgroundColor" class="bg-image"/>
				<img v-if="(customization && !customization.artworkCustomizationEnabled) || (customization && !customization.artworkBackgroundColor)" class="bg-image" :src="bgImage" alt="">
			</div>
			
			<img class="resource-image" :src="resource.url" alt="">
		</div>
		
		<div v-else-if="!isLoading && isAudio" class="content-audio">
			<div class="container">
				<div v-if="customization && customization.artworkCustomizationEnabled && customization.artworkBackgroundColor" class="bg-image"/>
				<img v-if="(customization && !customization.artworkCustomizationEnabled) || (customization && !customization.artworkBackgroundColor)" class="bg-image" :src="bgImage" alt="">
			</div>
			
			<div class="artwork-information">
				<img class="artwork" :src="artwork.mainImageUrl" alt="">
				
				<h2>{{ resource.title }}</h2>
				
				<audio-player v-if="resource.locale.url" class="audio-player"
				              :file="resource.locale.url"
				              :text-color="customization && customization.artworkCustomizationEnabled && customization.artworkTextColor ? customization.artworkTextColor : '#FFFFFF'"
				              :icons-color="customization && customization.artworkCustomizationEnabled && customization.artworkTextColor ? customization.artworkTextColor : '#FFFFFF'"/>
			</div>
		</div>
		
		<div v-else-if="!isLoading && isVideo " class="content-video">
			<video controls autoplay playsinline>
				<source :src="resource.locale.url" type="video/mp4">
			</video>
		</div>
		
		<footer>
			<button class="button-white" @click="goBack()">
				<i class="mm-icon mm-icon-close big"/>
			</button>
		</footer>
	</div>
</template>

<script>
const {debug} = require("@/misc/debug");
import * as mutationTypes from "@/store/mutation-types";
import { mapActions, mapState } from "vuex";
import Loading from "vue-loading-overlay";
import AudioPlayer from "@/components/AudioPlayer";
import {setFullScreen} from "@/store/helpers";
// import Load from '../../components/Load.vue';

export default {
	name: "Resource",
	
	components: {
		// Load,
		Loading,
		AudioPlayer
	},
	
	data() {
		return {
			encrypted: this.$route.params.idMuseum,
			idMuseum: "0",
			idArtwork: this.$route.params.idArtwork || "0",
			idResource: this.$route.params.idResource || "0",
		};
	},
	
	computed: {
		...mapState({
			museum: state => state.museum.museum,
			customization: state => state.museum.customization,
			isLoading: state => state.app.isLoading,
			isNetworkOnline: state => state.app.isNetworkOnline,
			artwork: state => state.artwork.artwork,
			artworkError: state => state.artwork.artworkError,
			resource: state => state.artwork.resource,
			resourceError: state => state.artwork.resourceError,
		}),
		
		pageTitle() {
			let locationName = this.$i18n.t('my_favourites_artworks');
			if(this.resource?.title) {
				locationName = this.resource.title;
			}
			
			let museumName = (this.museum?.name)
				? this.museum.name
				: this.museum?.locale?.name
					? this.museum.locale.name
					: "MuseumMate";
			
			return locationName + ' | ' + museumName;
		},
		
		bgImage() {
			let bgImage = null;
			
			if (!this.artworkError && this.artwork && this.artwork.hasOwnProperty('mainImageUrl')) {
				bgImage = this.artwork.mainImageUrl;
			}
			
			if(this.customization?.artworkCustomizationEnabled && this.customization.artworkBackgroundImageUrl) {
				bgImage = this.customization.artworkBackgroundImageUrl;
			}
			
			if (!bgImage) {
				bgImage = '@/assets/home.jpg';
			}
			
			return bgImage;
		},
		
		isText() {
			return this.resource && this.resource.hasOwnProperty('resourceType') && this.resource.resourceType === 'TEXT' && this.resource.locale;
		},
		
		isAudio() {
			return this.resource && this.resource.hasOwnProperty('resourceType') && this.resource.resourceType === 'AUDIO' && this.resource.locale;
		},
		
		isVideo() {
			return this.resource && this.resource.hasOwnProperty('resourceType') && this.resource.resourceType === 'VIDEO' && this.resource.locale;
		},
		
		isImage() {
			return this.resource && this.resource.hasOwnProperty('resourceType') && this.resource.resourceType === 'IMAGE';
		},
		
		cssVars() {
			return {
				'--artwork-text-color': (this.customization?.artworkCustomizationEnabled) ? this.customization.artworkTextColor : '#8f8f8f',
				'--artwork-background-color': (this.customization?.artworkCustomizationEnabled) ? this.customization.artworkBackgroundColor : '#ffffff',
			};
		}
	},
	
	created() {
		debug.log("[Resource] Creating view");
		this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, true);
		
		try {
			let decryption = (atob(this.encrypted));
			this.idMuseum = decryption.split('-')[0];
			
			this.loadMuseum(this.idMuseum)
				.then(async () => {
					await this.findArtwork(this.idArtwork);
					await this.findArtworkResource(this.idResource);
				})
				.finally(() => {
					this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
				});
		} catch(e) {
			if (process.env.VUE_APP_DEBUG_MODE == "true") console.error("Error deciphering: ", e);
			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
		}
	},
	
	methods: {
		...mapActions('app', ['loadMuseum']),
		...mapActions('artwork', ['findArtwork', 'findArtworkResource']),
		
		goBack() {
			setFullScreen();
			
			let queryFrom = null;
			if (this.$router.history?.current?.query?.from) {
				queryFrom = this.$router.history.current.query.from;
			}
			
			this.$router.push({
				name: 'Artwork',
				params: { idMuseum: this.encrypted, idArtwork: this.idArtwork },
				query: {
					...(queryFrom && { from: queryFrom }),
					...(this.$route.query.highlightedRoute && { highlightedRoute: this.$route.query.highlightedRoute })
				}
			});
		},
	}
};
</script>

<style lang="scss" scoped>
@import '../../theme/colors';

.content {
	width: 100%;
	height: 100%;
	
	.error-code {
		padding: 80px 20px 100px 20px;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		font-size: 22px;
	}
	
	.content-text {
		background-color: white;
		height: 100%;
		overflow-y: auto;
		
		h1 {
			font-weight: normal;
			text-transform: uppercase;
			border-bottom: 1px solid $gray-lightest;
			padding: 20px;
			width: 100%;
			position: fixed;
			top: 0;
			background-color: white;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
		
		p {
			color: $gray-light;
			font-size: 22px;
			padding: 80px 20px 110px 20px;
			white-space: pre-wrap;
		}
	}
	
	.container {
		overflow: hidden;
		position: fixed;
		width: 100%;
		height: 100%;
		z-index: -1;
		
		.bg-image {
			width: 100%;
			height: 100%;
			filter: blur(20px);
			transform: scale(1.5);
			background-color: var(--artwork-background-color);
		}
	}
	
	.content-image {
		display: flex;
		justify-content: center;
		
		.resource-image {
			padding: 20px;
			width: 100%;
			height: auto;
		}
	}
	
	.content-audio {
		.artwork-information {
			padding: 20px 20px 110px 20px;
			color: white;
			display: flex;
			flex-direction: column;
			height: calc(100% - 90px);
			
			.artwork {
				padding: 20px;
				width: 100%;
				height: auto;
			}
			
			h2 {
				font-weight: normal;
				font-size: 24px;
				margin-bottom: 10px;
				color: var(--artwork-text-color);
				border-bottom: 1px solid var(--artwork-text-color);
			}
		}
	}
	
	.content-video {
		width: 100%;
		height: calc(100vh - 90px);
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: black;
		
		video {
			width: 100%;
			height: auto;
			max-height: 100%;
		}
	}
	
	footer {
		height: 90px;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		justify-items: center;
		position: fixed;
		bottom: 0;
		box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
		
		.button-white {
			border: none;
			background-color: white;
			height: 100%;
			color: black;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}
</style>
