<template>
  <div id="main" class="content" :style="cssVars">
    <vue-headful :title="pageTitle" />

     <loading :active.sync="isLoading" :is-full-page="true" />
	<!-- <Load v-if="isLoading"></Load> -->

    <div class="container">
      <div
        v-if="
          customization &&
          customization.artworkCustomizationEnabled &&
          customization.artworkBackgroundColor
        "
        class="bg-image"
      />
      <img
        v-if="
          (customization && !customization.artworkCustomizationEnabled) ||
          (customization && !customization.artworkBackgroundColor)
        "
        class="bg-image"
        :src="bgImage"
        alt=""
      >
    </div>

    <main v-if="artworkError" class="error">
      <h2>{{ $t("no_artwork") }}</h2>
    </main>

    <main v-if="!isLoading && artwork && artwork.locale">
        <div class="artwork">
        <img
          v-if="artwork && artwork.mainImageUrl"
          class="artwork-image"
          :src="isNetworkOnline ? getImageUrl(artwork.mainImageUrl) : imageArtwork"
          :alt="artwork.locale.name"
        >
          <!--				<div class="text-floating-mainImage">
					{{ artwork.mainImageUrl }}
				</div>-->
      </div>

      <h2>{{ artwork.locale.name }}</h2>

      <div class="artwork-subtitle">
        <h3>{{ artwork.author }}</h3>
        <div v-if="artwork.showCode" class="artwork-title-code">
          <i class="mm-icon mm-icon-audio white" />
          {{ artwork.code }}
        </div>
      </div>

      <h4>{{ artwork.locale.artworkDate }}</h4>

      <audio-player
        v-if="artwork.locale.mainAudioUrl"
        class="audio-player"
        :file="artwork.locale.mainAudioUrl"
        :text-color="
          customization &&
          customization.artworkCustomizationEnabled &&
          customization.artworkTextColor
            ? customization.artworkTextColor
            : '#FFFFFF'
        "
        :icons-color="
          customization &&
          customization.artworkCustomizationEnabled &&
          customization.artworkTextColor
            ? customization.artworkTextColor
            : '#FFFFFF'
        "
        :additionalInformation="getAdditionalInformation()"
      />

      <p v-if="artwork.locale.mainText">{{ artwork.locale.mainText }}</p>
    </main>

    <footer :style="footerCSSVars">
      <div class="footer-buttons">
        <button class="button-white" @click="goBack()">
          <i class="mm-icon mm-icon-close big" />
        </button>
        <button
          v-if="
            artwork && artwork.extraResources && artwork.extraResources.length
          "
          class="button-resources"
          :style="footerButtonColorCSSVar"
          @click="isResourceListOpen = !isResourceListOpen"
        >
          <i
            v-show="!isResourceListOpen"
            class="mm-icon mm-icon-add white big"
          />
          <i
            v-show="isResourceListOpen"
            class="mm-icon mm-icon-remove white big"
          />
        </button>
        <button
          v-if="museum.recognitionAvailable"
          class="button-recognition"
          @click="goToRecognition()"
        >
          <img
            v-if="customization && customization.recognitionIconUrl"
            class="recognition-icon"
            :src="customization.recognitionIconUrl"
            alt=""
          >
          <img
            v-else
            class="recognition-icon"
            src="@/assets/icons/pointplay.png"
            alt=""
          >
        </button>
      </div>

      <ul
        v-if="artwork && artwork.extraResources"
        v-show="isResourceListOpen"
        class="resources"
        :style="[footerCSSVars, footerButtonColorCSSVar]"
      >
        <li
          v-for="resource in artwork.extraResources"
          :key="resource.idResource"
          @click="goToResource(resource)"
        >
          <i
            v-if="resource.resourceType === 'AUDIO'"
            class="mm-icon mm-icon-audio white"
          />
          <i
            v-if="resource.resourceType === 'IMAGE'"
            class="mm-icon mm-icon-picture white"
          />
          <i
            v-if="resource.resourceType === 'VIDEO'"
            class="mm-icon mm-icon-video white"
          />
          <i
            v-if="resource.resourceType === 'TEXT'"
            class="mm-icon mm-icon-info white"
          />

          <p class="resource-title">{{ resource.title }}</p>
        </li>
      </ul>
    </footer>
  </div>
</template>

<script>
const {debug} = require("@/misc/debug");
import * as mutationTypes from "@/store/mutation-types";
import {mapActions, mapState} from "vuex";
import Loading from "vue-loading-overlay";
import 'vue-loading-overlay/dist/vue-loading.css';
import AudioPlayer from "@/components/AudioPlayer";
import {setFullScreen} from "@/store/helpers";
import ArtworkVisit, {AccessType} from "@/models/artworkVisit";
import MuseumsAPI from "@/api/MuseumsAPI";
import firebase from "firebase/app";
import idb from "../../api/base/idb";
// import Load from '../../components/Load.vue';

export default {
	name: "Artwork",
	
	components: {
		// Load,
		Loading,
		AudioPlayer
	},
	
	data() {
		return {
			encrypted: this.$route.params.idMuseum,
			idMuseum: "0",
			idArtwork: this.$route.params.idArtwork || "0",
			isResourceListOpen: false,
			artworkVisit: new ArtworkVisit(),
			sectionExhibitions: null,
			sectionRoutes: null,
			sectionTimeline: null,
			keyboardCustomization: null,
			from: this.$route.query.from,
			imageArtwork:null,
		};
	},
	
	computed: {
		...mapState({
			museum: state => state.museum.museum,
			customization: state => state.museum.customization,
			isLoading: state => state.app.isLoading,
			isNetworkOnline: state => state.app.isNetworkOnline,
			viewFrom: state => state.app.viewFrom,
			artwork: state => state.artwork.artwork,
			artworkError: state => state.artwork.artworkError,
			navigationSections: state => state.navigationSection.navigationSections,
			navigationSectionsError: state => state.navigationSection.navigationSectionsError,
			visit: state => state.app.visit,
			visitv2: state => state.app.visitv2,
			route: state => state.route.route,
			studyQuestions: state => state.studyQuestion.studyQuestions,
			studyQuestionsError: state => state.studyQuestion.studyQuestionsError,
		}),
		
		pageTitle() {
			let locationName = this.$i18n.t('my_favourites_artworks');
			if(this.artwork?.locale?.name) {
				locationName = this.artwork.locale.name;
			}
			
			let museumName = (this.museum?.name)
				? this.museum.name
				: this.museum?.locale?.name
					? this.museum.locale.name
					: "MuseumMate";
			
			return locationName + ' | ' + museumName;
		},
		
		bgImage() {
			let bgImage = null;
			
			if (!this.artworkError && this.artwork && this.artwork.hasOwnProperty('mainImageUrl')) {
				bgImage = this.artwork.mainImageUrl;
			}
			
			if(this.customization?.artworkCustomizationEnabled && this.customization.artworkBackgroundImageUrl) {
				bgImage = this.customization.artworkBackgroundImageUrl;
			}
			
			if (!bgImage) {
				bgImage = '@/assets/home.jpg';
			}
			
			return bgImage;
		},
		
		footerCSSVars() {
			let height = 90;
			
			if (this.artwork && this.artwork.extraResources && this.isResourceListOpen) {
				height = this.artwork.extraResources.length * 50 + 90;
			}
			
			if (height > 290) {
				height = 290;
			}
			
			return {
				'--footer-height': height + 'px',
				'--recognition-color': (this.customization?.mainButtonsCustomizationEnabled) ? this.customization.recognitionColor : '#f06140',
			};
		},
		
		footerButtonColorCSSVar() {
			let primaryBlue = (this.customization?.mainButtonsCustomizationEnabled) ? this.customization.mainButtonsColor : '#72c4d0';
			let colorExhibitions = '#17779b';
			let colorRoutes = '#d1b490';
			let colorTimeline = '#94af49';
			
			let buttonColor = primaryBlue;
			let buttonTextColor = '#ffffff';
			switch (this.viewFrom.name) {
				case 'Keyboard':
					buttonColor = primaryBlue;
					buttonTextColor = (this.customization?.mainButtonsCustomizationEnabled) ? this.customization.mainButtonsTextColor : buttonTextColor;
					break;
				case 'Exhibition':
					buttonColor = (this.sectionExhibitions?.active && this.sectionExhibitions.lineColor) ? this.sectionExhibitions.lineColor : colorExhibitions;
					buttonTextColor = (this.sectionExhibitions?.active && this.sectionExhibitions.arrowColor) ? this.sectionExhibitions.arrowColor : buttonTextColor;
					break;
				case 'OutstandingArtworks':
				case 'Route':
					buttonColor = (this.sectionRoutes?.active && this.sectionRoutes.lineColor) ? this.sectionRoutes.lineColor : colorRoutes;
					buttonTextColor = (this.sectionRoutes?.active && this.sectionRoutes.arrowColor) ? this.sectionRoutes.arrowColor : buttonTextColor;
					break;
				case 'timeline':
					buttonColor = (this.sectionTimeline?.active && this.sectionTimeline.lineColor) ? this.sectionTimeline.lineColor : colorTimeline;
					buttonTextColor = (this.sectionTimeline?.active && this.sectionTimeline.arrowColor) ? this.sectionTimeline.arrowColor : buttonTextColor;
					break;
				default:
					buttonColor = primaryBlue;
					break;
			}
			
			return {
				'--footer-button-color': buttonColor,
				'--footer-button-text-color': buttonTextColor
			};
		},
		
		cssVars() {
			return {
				'--artwork-text-color': (this.customization?.artworkCustomizationEnabled) ? this.customization.artworkTextColor : '#8f8f8f',
				'--artwork-background-color': (this.customization?.artworkCustomizationEnabled) ? this.customization.artworkBackgroundColor : '#ffffff',
			};
		}
	},
	
	created() {
		debug.open("Artwork");
		debug.log("Creating view");
		this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, true);

		try {
			let decryption = (atob(this.encrypted));
			this.idMuseum = decryption.split('-')[0];
			this.loadMuseum(this.idMuseum)
				.then(async () => {
					await this.findArtwork(this.idArtwork);
					this.imageArtwork = await this.getblobImage();
					this.getNavigationSections();
					this.getStudyQuestions(this.idArtwork);
					this.sectionExhibitions = this.navigationSections.find((s) => s.sectionType === 'EXHIBITIONS');
					this.sectionRoutes = this.navigationSections.find((s) => s.sectionType === 'ROUTES');
					this.sectionTimeline = this.navigationSections.find((s) => s.sectionType === 'TIMELINE');
					if(this.museum.keyboardCustomization && this.museum.keyboardCustomization.active) {
						this.keyboardCustomization = this.museum.keyboardCustomization;
					}
				})
				.finally(() => {
          // register visit
            this.setArtworkVisit();
					if(firebase.app().options.measurementId) {
						debug.log("Logging page_view analytics");
						firebase.analytics().logEvent('page_view', {
							page_location: this.$router.currentRoute.fullPath,
							page_title: this.pageTitle
						});
					} else {
						debug.log("No measurementId found, no analytics sent");
					}
					debug.close();
					this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
				});
		} catch(e) {
			if (process.env.VUE_APP_DEBUG_MODE == "true") console.error("Error deciphering: ", e);
			debug.close();
			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
		}
	},

	methods: {
		...mapActions('app', ['loadMuseum']),
		...mapActions('artwork', ['findArtwork']),
		...mapActions('navigationSection', ['getNavigationSections']),
		...mapActions('studyQuestion', ['getStudyQuestions']),

    getImageUrl(imageBlob) {
      if (imageBlob instanceof Blob) {
        return URL.createObjectURL(imageBlob);
      } else {
        return imageBlob;
      }
    },

		setFullScreen(to = null) {
			setFullScreen();
			if(to) {
				this.$router.push(to);
			}
		},

		createNewArtworkVisit() {
			localStorage.removeItem('current-artwork-visit');
			
			let accessType = null;
			if(this.viewFrom.name === 'Keyboard') {
				accessType = AccessType.KEYBOARD;
			}
			
			let queryFrom = null;
			if (this.$router.history?.current?.query?.from) {
				queryFrom = this.$router.history.current.query.from;
				if(queryFrom === 'Recognition') {
					accessType = AccessType.RECOGNITION;
				}
			}
			this.artworkVisit = new ArtworkVisit(this.idArtwork, accessType, new Date(), new Date());
			this.$store.commit('app/' + mutationTypes.ADD_ARTWORK_VISIT, this.artworkVisit);
		},

		setArtworkVisit() {
			debug.open("setArtworkVisit");
			
			let currentArtworkVisit = localStorage.getItem('current-artwork-visit');
			try {
				currentArtworkVisit = JSON.parse(currentArtworkVisit);
				if(currentArtworkVisit.idArtwork === this.idArtwork) {
					debug.log("Continuing with the visit", currentArtworkVisit);
					this.artworkVisit.parseJSON(currentArtworkVisit);
				} else {
					debug.log("There is a visit ongoing, but it is not for this artwork, finishing it and creating a new one...");
					this.createNewArtworkVisit();
				}
			} catch(e) {
				debug.log("There is no visit ongoing, creating one...");
				this.createNewArtworkVisit();
			}
			
			localStorage.setItem('current-artwork-visit', JSON.stringify(this.artworkVisit));
			
			debug.close();
		},

		goToResource(resource) {
			setFullScreen();
			
			let queryFrom = null;
			if (this.$router.history?.current?.query?.from) {
				queryFrom = this.$router.history.current.query.from;
			}
			
			this.$router.push({
				name: 'Resource',
				params: {idResource: resource.idResource},
				query: {
					...(queryFrom && { from: queryFrom }),
					...(this.$route.query.highlightedRoute && { highlightedRoute: this.$route.query.highlightedRoute })
				}
			});
		},

		goToRecognition() {
			setFullScreen();
			
			let queryFrom = null;
			if (this.$router.history?.current?.query?.from) {
				queryFrom = this.$router.history.current.query.from;
			}
			
			this.$router.push({
				name: 'Recognition',
				params: { idMuseum: this.encrypted},
				query: {
					...(queryFrom && { from: queryFrom }),
					...(this.$route.query.highlightedRoute && { highlightedRoute: this.$route.query.highlightedRoute
					})
				} });
		},
		
		async goBack() {
			setFullScreen();
			const currentTime = document.getElementsByClassName("player")[0]?.firstChild.currentTime || 0;
			if (currentTime >= 1) {
          MuseumsAPI.updateVisitArtworkV2(this.visitv2.idVisit, this.idArtwork, currentTime);
			}
   
        MuseumsAPI.updateVisitMuseumV2(this.visitv2, mutationTypes.SET_VISITV2, this.$store);
			if(this.from === "RouteList"){
        MuseumsAPI.updateVisitRouteListV2(this.visitv2.idVisit, this.route.idRoute);
      }
			//Register the visit to this artwork
      if(this.isNetworkOnline){
        debug.open("Registering visit");
        this.$store.commit('app/' + mutationTypes.UPDATE_LAST_ARTWORK_VISIT);
        this.$store.commit('app/' + mutationTypes.UPDATE_VISIT_END_DATE, new Date());
        debug.log("Finished visit");
      }

    
        MuseumsAPI.registerVisit(this.idMuseum, {...this.visit, languageCode: this.visitv2?.languageCode || this.visit.languageCode})
            .then((response) => {
              this.$store.commit('app/' + mutationTypes.UPDATE_ID_VISIT, response.data?.data?.IdVisit, {root: true});
            })
            .catch((e) => {
              if (process.env.VUE_APP_DEBUG_MODE == "true") console.error(e);
              localStorage.setItem('visit', JSON.stringify(this.visit));
            })
            .finally(() => {
              this.$store.commit('app/' + mutationTypes.SET_VISIT, this.visit, {root: true});
              this.$store.commit('app/' + mutationTypes.DELETE_ARTWORK_VISIT);
              debug.close();
            });
    

			//Attach surveys after a specific artwork visit
			let queryFrom = null;
			if (this.$router.history?.current?.query?.from) {
				queryFrom = this.$router.history.current.query.from;
			}

			const isStudyMade = localStorage.getItem('study-artwork-' + this.idArtwork);
			let hasStudyQuestions = Object.values(this.studyQuestions).some(q => +q.idArtwork === +this.idArtwork);
			if(!isStudyMade && hasStudyQuestions) {
				await this.$router.push({
					name: 'StudyQuestionArtwork',
					params: { idMuseum: this.encrypted, idArtwork: this.idArtwork },
					...(queryFrom && { query: { from: queryFrom }})
				});
			} else {
				if (this.viewFrom.name === 'Beacon') {
					this.$router.go(-1);
				} else if (this.viewFrom.name === 'Route'
					|| this.viewFrom.name === 'Exhibition'
					|| this.viewFrom.name === 'Timeline'
					|| this.viewFrom.name === 'Game') {
					await this.$router.push({
						name: this.viewFrom.name,
						params: this.viewFrom.params,
						query: {
							artwork: this.idArtwork,
							...(queryFrom && { from: queryFrom }),
							...(this.$route.query.highlightedRoute && { highlightedRoute: this.$route.query.highlightedRoute })
						}
					});
				} else {
					await this.$router.push({
						name: this.viewFrom.name,
						params: this.viewFrom.params,
						...(queryFrom && { query: { from: queryFrom }}),
						...(this.$route.query.highlightedRoute && { highlightedRoute: this.$route.query.highlightedRoute })
					});
				}
			}
		},
		getAdditionalInformation() {
			return this.artwork.otherResources.filter(r => r.isAdditionalInformation);
		},
		async getblobImage(){
			let id = this.idArtwork;
			let indexDbResult;
			try{
				if ( id!== null){
					indexDbResult =  await idb.getFromDB(parseInt(id),'artworks');
				}
			} 
			catch(e){
				console.log('An error has occurred in getblobImage',e);
			}
			if (indexDbResult){
			return this.getImageUrl(indexDbResult.mainImageUrl);
			}
	}
	},
};
</script>

<style lang="scss" scoped>
@import '../../theme/colors';

.text-floating-mainImage {
	color: var(--artwork-text-color);
	font-size: 10px;
	width: 100%;
}
.content {
	width: 100%;
	height: 100%;

	.container {
		overflow: hidden;
		position: fixed;
		width: 100%;
		height: 100%;
		z-index: -1;

		.bg-image {
			width: 100%;
			height: 100%;
			filter: blur(20px);
			transform: scale(1.5);
			background-color: var(--artwork-background-color);
		}
	}

	main {
		padding: 20px;
		color: white;
		display: flex;
		flex-direction: column;
		height: calc(100% - 90px);
		overflow-y: auto;

		.artwork {
			padding: 20px 20px 0 20px;
			width: 100%;
			text-align: center;
			position: relative;
			.artwork-image {
				max-width: 100%;
				height: auto;
				max-height: 300px;
			}
		}

		h2 {
			color: var(--artwork-text-color);
			font-weight: normal;
			font-size: 32px;
			margin-bottom: 10px;
		}

		.artwork-subtitle {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 10px;

			h3 {
				color: var(--artwork-text-color);
				font-weight: normal;
				margin-right: 20px;
			}

			.artwork-title-code {
				display: flex;
				justify-content: space-between;
				align-items: center;
				color: var(--artwork-text-color);

				i {
					color: var(--artwork-text-color);
					margin-right: 10px;
					margin-bottom: -5px;
				}
			}

		}

		h4 {
			color: var(--artwork-text-color);
			font-weight: normal;
			padding: 5px 0;
			margin-bottom: 20px;
			border-bottom: 1px solid var(--artwork-text-color);
		}

		.audio-player {
			margin-bottom: 20px;
		}

		p {
			color: var(--artwork-text-color);
			font-size: 20px;
			white-space: pre-wrap;
		}
	}

	.error {
		height: calc(100vh - 90px);
		display: flex;
		justify-content: center;
		align-items: center;

		h2 {
			color: $red;
			font-size: 32px;
		}
	}

	footer {
		position: fixed;
		bottom: 0;
		height: var(--footer-height);
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		z-index: 100;

		.footer-buttons {
			width: 100%;
			min-height: 90px;
			display: flex;
			justify-content: center;
			position: absolute;
			top: 0;

			button {
				width: 100%;
				border: none;
				text-transform: uppercase;
				display: flex;
				justify-content: center;
				align-items: center;

				&.button-white {
					background-color: white;
					color: black;
				}

				&.button-resources {
					background-color: var(--footer-button-color);
					color: var(--footer-button-text-color);

					.mm-icon {
						color: var(--footer-button-text-color);
					}
				}

				&.button-recognition {
					color: white;
					background-color: var(--recognition-color);

					.recognition-icon {
						max-width: 60%;
						max-height: 80%;
					}
				}
			}
		}

		.resources {
			background-color: var(--footer-button-color);
			position: fixed;
			width: 100%;
			left: 0;
			bottom: 0;
			max-height: 200px;
			overflow-y: scroll;

			li {
				display: flex;
				align-items: center;
				padding: 15px;
				min-height: 50px;
				color: var(--footer-button-text-color);

				&:not(:last-child) {
					border-bottom: 1px solid $gray-lightest;
				}

				i {
					font-size: 22px;
					margin-right: 10px;
					margin-bottom: -5px;
					color: var(--footer-button-text-color);
				}

				.resource-title {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}
	}
}
</style>
